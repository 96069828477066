


























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import { artGeboudeItems, gebaudeartAltbauItems, gebaudeartItems } from '@/constant/constants';
import { copyObject, onCurrencyFocus, recursiveFillData, handlePremiumCategory, distributeCoefficients, altbauNachIsDisable, disableAltbauNachIfValuesFalse } from '@/helper/functions';
import { exportToPDF, saveZusammenfassungPDF } from '@/helper/handle-pdf';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';
import priceStatusIcon from '@/components/form/price-status-icon.vue';
import popUpModal from '@/components/form/pop-up-modal.vue';
import watchers from '@/watchers/change-data-for-price-request/bauleistung-insurance/calc-tab';
import popupModalWithSlot from '@/components/form/popup-modal-with-slot.vue';
import { CustomErrorMessagesInterface, CustomValidator } from '@/helper/custom-validator';
import FormCheckbox from '@/components/form/form-checkbox.vue';

const VersicherungssummeNeubauleistungItem = {
  GenerelleSelbstbeteiligung: null,
  SelbstbehaltPercent: null,
  SelbstbehaltRohbau: null,
  SelbstbehaltAusbau: null,
};
const AltbauRisicoSelectItems = {
  risiko1: null,
  risiko2: null,
  risiko3: null,
  risiko1Disabled: false,
  risiko2Disabled: false,
  risiko3Disabled: false,
};
const AltbauRisiconumberItems = {
  risiko1: null,
  risiko2: null,
  risiko3: null,
};
const GrundwertAdditionalItem = {
  name: '',
  Grundwert: 2,
  neuerWert: null,
};
type additionalItemType = {
  name: string | null;
  Grundwert: number | null;
  neuerWert: string | null;
};
const AltbauNachAZ5155Item = {
  name: '',
  BeitragssatzPromile: null,
  VSinEUR: null,
  SBinEUR: 500,
  SBinPercent: 20,
  EinschlussSturm: null,
  EinschlussBauherrenrisiko: null,
  Strabe: null,
  PLZ: null,
  Ort: null,
};
const AltbauNachAZ5180Item = {
  name: '',
  Gebaudeart: null,
  BeitragssatzPromile: null,
  VSinEUR: null,
  SBinEUR: 500,
  SBinPercent: 10,
};
const BesondereBaumabnahmenItem = {
  name: '',
  BeitragssatzPromile: null,
  Versicherungssumme: null,
};

const AZ5155 = 'AZ 5155/6155';
const AZ5180 = 'AZ 5180';
const AZ5181 = 'AZ 5181';

const klauselItems = [
  AZ5155,
  AZ5180,
  AZ5181,
];

const mitversicherungItems = [
  'Ja',
  'Nein',
  'Optional',
];

const ersZumNeuwert = 'ERS zum Neuwert';
const ersZumZeitwert = 'ERS zum Zeitwert';

const erstesRisiko = 'Erstes Risiko';

const entschadigungsvarianteItems = [
  ersZumNeuwert,
  ersZumZeitwert,
];

const newConstruction = 'Neubau-/Wiederherstellungswert';

const altbau5180DecisionOptions = [
  ersZumNeuwert,
  ersZumZeitwert,
  newConstruction,
];

const coverVariants = [
  'Einfache Deckung AZ 5291',
  'Erweiterte Deckung AZ 5290',
];

const coverageOptionAZ5181 = [
  newConstruction,
  erstesRisiko,
];

const altbauObject = {
  selected: 'Optional',
  RisikoItems: {
    Klausel: {
      name: 'Klausel',
      type: 'select',
      options: [
        ...klauselItems,
      ],
      risiko1: AZ5155,
      risiko2: AZ5180,
      risiko3: AZ5181,
      risiko1Disabled: true,
      risiko2Disabled: true,
      risiko3Disabled: true,
    },
    Mitversicherung: {
      name: 'Mitversicherung',
      type: 'select',
      options: [
        ...mitversicherungItems,
      ],
      ...copyObject(AltbauRisicoSelectItems),
    },
    Entschadigungsvariante: {
      name: 'Entschädigungsvariante',
      type: 'select',
      options: [
        ...entschadigungsvarianteItems,
      ],
      ...copyObject(AltbauRisicoSelectItems),
      risiko1: 'ERS 5155',
      risiko1Disabled: true,
    },
    Versicherungssumme: {
      name: 'Versicherungssumme',
      type: 'money',
      ...copyObject(AltbauRisiconumberItems),
    },
    SelbstbeteiligungInPercent: {
      name: 'Selbstbeteiligung in %',
      type: 'percent',
      risiko1: 20 as number | null,
      risiko2: 10 as number | null,
      risiko3: 10 as number | null,
    },
    SelbstbeteiligungInEUR: {
      name: 'Selbstbeteiligung in EUR',
      type: 'money',
      risiko1: 500 as number | null,
      risiko2: 500 as number | null,
      risiko3: 500 as number | null,
    },
    BeitragAltbau: {
      name: 'Beitragssatz Altbau',
      type: 'promille',
      ...copyObject(AltbauRisiconumberItems),
    },
  },
  IndividuelleRisikoinformationenZumAltbau: {
    name: 'Individuelle Risikoinformationen zum Altbau',
    value: 'Nein',
  },
};

const altbauNachAZ5155Object = {
  value: null,
  items: {
    AltbauIndividualA: {
      ...copyObject(AltbauNachAZ5155Item),
      name: 'Altbau Individual A',
    },
    AltbauIndividualB: {
      ...copyObject(AltbauNachAZ5155Item),
      name: 'Altbau Individual B',
    },
    AltbauIndividualC: {
      ...copyObject(AltbauNachAZ5155Item),
      name: 'Altbau Individual C',
    },
  },
  Beschreibung: null,
};

const altbauNachAZ5180Object = {
  value: null,
  Deckungsvariante: null,
  Entschadigungsvariante: null,
  items: {
    AltbauIndividualA: {
      ...copyObject(AltbauNachAZ5180Item),
      name: 'Altbau Individual A',
    },
    AltbauIndividualB: {
      ...copyObject(AltbauNachAZ5180Item),
      name: 'Altbau Individual B',
    },
    AltbauIndividualC: {
      ...copyObject(AltbauNachAZ5180Item),
      name: 'Altbau Individual C',
    },
  },
  Beschreibung: null,
  BesondereBaumabnahmenSelect: {
    AltbauIndividualA: false,
    AltbauIndividualB: false,
    AltbauIndividualC: false,
  },
  BesondereBaumabnahmen: {
    MedizinUndLabortechnAnlagen: {
      ...copyObject(BesondereBaumabnahmenItem),
      name: 'Medizin- und labortechn. Anlagen',
    },
    AnlagenDatenverarbBildTontechnik: {
      ...copyObject(BesondereBaumabnahmenItem),
      name: 'Anlagen Datenverarb., Bild-/Tontechnik',
    },
    SonstEnergieerzeugungsanlagen: {
      ...copyObject(BesondereBaumabnahmenItem),
      name: 'Sonst. Energieerzeugungsanlagen',
    },
    BesondereNutzungsspezAnlagen: {
      ...copyObject(BesondereBaumabnahmenItem),
      name: 'Besondere Nutzungsspez.Anlagen',
    },
    MaschnEinrichtFProduktionszwecke: {
      ...copyObject(BesondereBaumabnahmenItem),
      name: 'Maschn.Einricht. f.Produktionszwecke',
    },
    AufwendigeAusstattung: {
      ...copyObject(BesondereBaumabnahmenItem),
      BeitragssatzPromile: 30,
      name: 'Aufwendige Ausstattung',
    },
    BestandteileVonHohemKunstwert: {
      ...copyObject(BesondereBaumabnahmenItem),
      name: 'Bestandteile von hohem Kunstwert',
    },
    Schadensuchkosten: {
      ...copyObject(BesondereBaumabnahmenItem),
      name: 'Schadensuchkosten',
    },
  },
  BesondereRisikomerkmale: {
    DachhautAuBenhautGeoffnet: null,
    Ausbaugewerke: null,
    Ausschlusse: null,
  },
};

const altbauNachAZ5181Object = {
  value: null,
  Deckungsvariante: null,
  Entschadigungsvariante: '',
  items: {
    AltbauIndividualA: {
      ...copyObject(AltbauNachAZ5180Item),
      name: 'Altbau Individual A',
    },
    AltbauIndividualB: {
      ...copyObject(AltbauNachAZ5180Item),
      name: 'Altbau Individual B',
    },
    AltbauIndividualC: {
      ...copyObject(AltbauNachAZ5180Item),
      name: 'Altbau Individual C',
    },
  },
  Beschreibung: null,
  BesondereBaumabnahmenSelect: {
    AltbauIndividualA: false,
    AltbauIndividualB: false,
    AltbauIndividualC: false,
  },
  BesondereBaumabnahmen: {
    MedizinUndLabortechnAnlagen: {
      ...BesondereBaumabnahmenItem,
      name: 'Medizin- und labortechn. Anlagen',
    },
    AnlagenDatenverarbBildTontechnik: {
      ...BesondereBaumabnahmenItem,
      name: 'Anlagen Datenverarb., Bild-/Tontechnik',
    },
    SonstEnergieerzeugungsanlagen: {
      ...BesondereBaumabnahmenItem,
      name: 'Sonst. Energieerzeugungsanlagen',
    },
    BesondereNutzungsspezAnlagen: {
      ...BesondereBaumabnahmenItem,
      name: 'Besondere Nutzungsspez.Anlagen',
    },
    MaschnEinrichtFProduktionszwecke: {
      ...BesondereBaumabnahmenItem,
      name: 'Maschn.Einricht. f.Produktionszwecke',
    },
    AufwendigeAusstattung: {
      ...BesondereBaumabnahmenItem,
      BeitragssatzPromile: 30,
      name: 'Aufwendige Ausstattung',
    },
    BestandteileVonHohemKunstwert: {
      ...BesondereBaumabnahmenItem,
      name: 'Bestandteile von hohem Kunstwert',
    },
    Schadensuchkosten: {
      ...BesondereBaumabnahmenItem,
      name: 'Schadensuchkosten',
    },
  },
  BesondereRisikomerkmale: {
    DachhautAuBenhautGeoffnet: null,
    AusbaugewerkeZumNeuwert: null,
    AusschlussEinsturz: null,
    WeitereAusschlusse: null,
  },
  Nachhaftung: {
    MitversicherungNachhaftung: '',
    Variante: '',
    Monate: null,
  },
};

const hochstentschadigungNaturgefahrenGemabAZ5793Object = {
  HEfurNaturgefahren: null,
};

const versicherungssummeNeubauleistungObject = {
  NeubauIndividualRisikogruppe1: {
    name: 'Neubau Individual Risikogruppe 1',
    BeitragssatzPercent: null,
    Versicherungssumme: null,
    EinzelbeitragNeubauleistung: 0,
  },
  NeubauIndividualRisikogruppe2: {
    name: 'Neubau Individual Risikogruppe 2',
    BeitragssatzPercent: null,
    Versicherungssumme: null,
    EinzelbeitragNeubauleistung: 0,
  },
  Vorsteuerabzugsberechtigt: {
    name: 'Vorsteuerabzugsberechtigt',
    value: '',
  },
  BereitsVerbauteLeistung: {
    name: 'Bereits verbaute Leistung',
    value: null,
  },
};

const selbstbeteiligungNeubauleistungObject = {
  tarif: {
    name: 'Tarif SB',
    ...copyObject(VersicherungssummeNeubauleistungItem),
    GenerelleSelbstbeteiligung: 500,
  },
  variant1: {
    name: 'SB Variante 1',
    ...copyObject(VersicherungssummeNeubauleistungItem),
  },
  variant2: {
    name: 'SB Variante 2',
    ...copyObject(VersicherungssummeNeubauleistungItem),
  },
  variant3: {
    name: 'SB Variante 3',
    ...copyObject(VersicherungssummeNeubauleistungItem),
  },
};

const hilsbautenUndBauhilfsstoffeObject = {
  name: 'Hilsbauten und Bauhilfsstoffe',
  value: null,
  procent: null as null | number,
};

const disableEstablishmentMeasuresInputsCondition = 5000000;

export default Vue.extend({
  name: 'bauleistung-insurance-ABN',
  mixins: [dataChangeMixin],
  components: {
    popupModalWithSlot,
    priceStatusIcon,
    popUpModal,
    FormCheckbox,
  },
  data() {
    return {
      name: 'bauleistung-insurance-basic',
      key: 'CONSTRUCTION_WORK_INSURANCE',
      backUrl: 'bauleistung-insurance',
      modalOpen: false,
      submitedForm: false,
      modalTitle: '',
      modalBody: '',
      Beschreibung: {
        ArtDerBaumabnahme: '',
        ArtGeboude1: '',
        ArtGeboude2: '',
        Baubeschreibung: '',
      },
      VersicherungssummeNeubauleistung: copyObject(versicherungssummeNeubauleistungObject),
      SelbstbeteiligungNeubauleistung: copyObject(selbstbeteiligungNeubauleistungObject),
      Altbau: copyObject(altbauObject),
      Grundungsmabnahmen: {
        BerlinerVerbau: {
          name: 'Berliner Verbau',
          value: null,
        },
        Spundwande: {
          name: 'Spundwände',
          value: null,
        },
        Bohrpfahlwande: {
          name: 'Bohrpfahlwände',
          value: null,
        },
        Schlitzwande: {
          name: 'Schlitzwände',
          value: null,
        },
        HDIWande: {
          name: 'HDI-Wände',
          value: null,
        },
        HangverbauBoschungsabst: {
          name: 'Hangverbau/Böschungsabst.',
          value: null,
        },
        WasserhaltungAndGWAbsenkung: {
          name: 'Wasserhaltung, GW-Absenkung',
          value: null,
        },
        GeklGeschwWasserDicht: {
          name: 'Gekl./Geschw. Wasser. Dicht.',
          value: null,
        },
        BaugrundverbChemVerf: {
          name: 'Baugrundverb. Chem.Verf.',
          value: null,
        },
        PfahlBrunnenSenkgrundung: {
          name: 'Pfahl-, Brunnen-, Senkgründung',
          value: null,
        },
        HDISohlenUnterwBetonsohlen: {
          name: 'HDI-Sohlen, Unterw.betonsohlen',
          value: null,
        },
        Erdwarmeborhungen100m400m: {
          name: 'Erdwärmeborhungen > 100m <400m',
          value: null,
        },
      },
      MitversicherungBesondererBaumabnahmen: {
        MedizinLabortechnischeAnlagen: {
          name: 'Medizin-/labortechnische Anlagen',
          value: null,
        },
        DatenverarbAnlagenBildUndTontechnik: {
          name: 'Datenverarb. Anlagen/Bild und Tontechnik',
          value: null,
        },
        BestandteileVHohemKunstwert: {
          name: 'Bestandteile v. hohem Kunstwert',
          value: null,
          procent: null as null | number,
        },
        SonstEnergieerzeugungsanlagen: {
          name: 'Sonst. Energieerzeugungsanlagen',
          value: null,
        },
        BesondereNutzungsspezifischeAnlagen: {
          name: 'Besondere nutzungsspezifische Anlagen',
          value: null,
        },
        HochwertigeFassadenGlasdacher: {
          name: 'Hochwertige Fassaden/Glasdächer',
          value: null,
        },
        MembranDacheindFassade: {
          name: 'Membran- Dacheind./Fassade',
          value: null,
        },
      },
      HilsbautenUndBauhilfsstoffe: copyObject(hilsbautenUndBauhilfsstoffeObject),
      Erstrisikosummen: {
        items: {
          BaugrundBodenmassen: {
            Grundwert: 100000,
            neuerWert: null,
            name: 'Baugrund/Bodenmassen',
          },
          HilfsbautenUndBauhilfsstoffe: {
            Grundwert: 100000,
            neuerWert: null,
            name: 'Hilfsbauten und Bauhilfsstoffe',
          },
          ArbeitsUndFasadengeruste: {
            Grundwert: 50000,
            neuerWert: null,
            name: 'Arbeits-und Fasadengerüste',
          },
          BautafelERS: {
            Grundwert: 5000,
            neuerWert: null,
            name: 'Bautafel ERS',
          },
          LokalisierungSchadenurs: {
            Grundwert: 50000,
            neuerWert: null,
            name: 'Lokalisierung Schadenurs.',
          },
          ZusatzlicheAufraumkosten: {
            Grundwert: 100000,
            neuerWert: null,
            name: 'Zusätzliche Aufräumkosten',
          },
          ZusatzlAufraumkostenMitF: {
            Grundwert: 100000,
            neuerWert: null,
            name: 'Zusätzl. Aufräumkosten mit F',
          },
          BohrlochVerfullkosten: {
            Grundwert: 50000,
            neuerWert: null,
            name: 'Bohrloch Verfüllkosten',
          },
          BeschleunigungskUberst: {
            Grundwert: 50000,
            neuerWert: null,
            name: 'Beschleunigungsk./Überst.',
          },
          KostenEilExpressLuftfracht: {
            Grundwert: 50000,
            neuerWert: null,
            name: 'Kosten Eil-,Express-/Luftfracht',
          },
        },
        versicherbareKostenpositionenLimitItems: {
          ReparaturkostenAnFremdenSachen: {
            Grundwert: 25000,
            neuerWert: null,
            name: 'Reparaturkosten an fremden Sachen',
          },
          HotelUndEinlagerungskosten: {
            Grundwert: 25000,
            neuerWert: null,
            name: 'Hotel- und Einlagerungskosten',
          },
          Mietausfall: {
            Grundwert: 25000,
            neuerWert: null,
            name: 'Mietausfall',
          },
          BewegungskostenAnNichtVersichertenSachen: {
            Grundwert: 25000,
            neuerWert: null,
            name: 'Bewegungskosten an nicht versicherten Sachen',
          },
        },
        additionalItems: [] as additionalItemType[],
      },
      SachenImGefahrenbereich: {
        SachenImGefahrenbereich: 'Nein',
        Variante: '',
        BenannteBaulichkeiten: '',
        Erstrisikosumme: null,
        BeitragssatzZuschlag: null,
        Zuschlag: null,
        Umkreis: 0,
        SelbstbeteiligungPercent: null,
        SBmindEUR: null,
      },
      MitversicherungVonFeuer: {
        MitversicherungVonFeuer: 'Nein',
        BaudauerInMonaten: null,
        Baunebenkosten: null,
      },
      Deckungseinschrankungen: {
        AbschlussAZ5761: '',
        Glasschaden: '',
        AusschlussDiebstahl: '',
      },
      Deckungserweiterungen: {
        BaudauerUber24Monate: '',
        RegressverzichtAZ5868: '',
        InnereUnruhenAZ5236: '',
        StreikAussperrungAZ5237: '',
        GrobeFahrlassigkeit: null,
        Insolvenzrisiko: null,
        PflanzenAnsaaten: null,
        AktenZeichnungenUndPlane: null,
        AktenZeichnungenUndPlanePercent: null,
        VerlustVersicherterSachen: null,
      },
      MitversicherungGewasserrisiko: {
        MitversicherungAZ5260: 'Nein',
        SelbstbehaltInPercent: null,
        SelbstbehaltMinimum: null,
      },
      Nachhaftung: {
        MitversicherungNachhaftung: 'Nein',
        Variante: '',
        Monate: null as number|null,
      },
      HochstentschadigungNaturgefahrenGemabAZ5793: copyObject(hochstentschadigungNaturgefahrenGemabAZ5793Object),
      AltbauNachAZ5155: copyObject(altbauNachAZ5155Object),
      AltbauNachAZ5180: copyObject(altbauNachAZ5180Object),
      AltbauNachAZ5181: copyObject(altbauNachAZ5181Object),
      calculation: null,
      noPrice: false,
      price: 0,
      klauselItems,
      artGeboudeItems,
      gebaudeartItems,
      gebaudeartAltbauItems,
      altbau5180DecisionOptions,
      coverVariants,
      coverageOptionAZ5181,
      pdfStyles: false,
      deductibleVariantsCssClasses: {
        tarif: '',
        variant1: 'table-variant-1',
        variant2: 'table-variant-2',
        variant3: 'table-variant-3',
      },
      tariffPremiumCalculation: {
        tarif: {
          name: 'Tarif SB',
        },
        variant1: {
          name: 'SB Variante 1',
        },
        variant2: {
          name: 'SB Variante 2',
        },
        variant3: {
          name: 'SB Variante 3',
        },
      },
      tootipText: '<b>Beitragssatz (in ‰ aus der vereinbarten Erstrisikosumme)</b><br>' +
        'je nach Art des Risikos, der Selbstbeteiligung und der Höhe der 3,0 ‰ – 6,0 ‰<br>' +
        'Erstrisikosumme (bei Erstrisikosumme > 5 Mio. EUR => Abstimmung mit HV)<br>' +
        'Bitte berücksichtigen, dass der Beitrag rabattiert wird. Es ist möglich einen Beitragssatz von bis zu 15 ‰ einzugeben, um einen späteren Rabatt auszugleichen.',
      altbauPremiums: {
        AltbauNachAZ5155: {
          AltbauIndividualA: {
          },
          AltbauIndividualB: {
          },
          AltbauIndividualC: {
          },
        },
        AltbauNachAZ5180: {
          AltbauIndividualA: {
          },
          AltbauIndividualB: {
          },
          AltbauIndividualC: {
          },
        },
        AltbauNachAZ5181: {
          AltbauIndividualA: {
          },
          AltbauIndividualB: {
          },
          AltbauIndividualC: {
          },
        },
      },
      openedCustomModals: {
        contributionRateSurchargeModal: false,
      },
      errorMessages: {
        sumInsured1: '',
        sumInsured2: '',
        contributionRate1: '',
        contributionRate2: '',
      },
      customValidator: new CustomValidator(),
    };
  },
  props: ['insuranceKey', 'caseStatus', 'insuranceData', 'processSavingData', 'productId', 'id', 'insuranceFormsApiData', 'priceData'],

  watch: {
    ...watchers,
    'Nachhaftung.MitversicherungNachhaftung': function (value: string) {
      if ((value === 'Ja' || value === 'Optional') && !this.Nachhaftung.Monate) {
        this.Nachhaftung.Monate = 12;
      }
    },
    'Nachhaftung.Monate': function (value: number) {
      if (this.Nachhaftung.MitversicherungNachhaftung === 'Ja') {
        this.AltbauNachAZ5181.Nachhaftung.Monate = value;
      }
    },
    disableSumInsuredForNewConstruction1(value: boolean) {
      if (value) {
        this.customValidator.validateById('contributionRate1');
      }
    },
    disableSumInsuredForNewConstruction2(value: boolean) {
      if (value) {
        this.customValidator.validateById('contributionRate2');
      }
    },
    disbleEstablishmentMeasuresFields(newValue: boolean) {
      if (newValue) {
        this.clearGrundungsmabnahmenValues();
      }
    },
    processSavingData(value: boolean) {
      if (this.insuranceData && this.insuranceData.basicData && this.VersicherungssummeNeubauleistung.NeubauIndividualRisikogruppe1.Versicherungssumme == null) {
        this.VersicherungssummeNeubauleistung.NeubauIndividualRisikogruppe1.Versicherungssumme = this.insuranceData.basicData.recordSumInsured.Versicherungssumme;
      }
    },
    showAz5155(value: boolean) {
      if (value && this.selectedAltbauAZ5155) {
        this.copyAltbauData(AZ5155);
      }
    },
    showAz5180(value: boolean) {
      if (value && this.selectedAltbauAZ5180) {
        this.copyAltbauData(AZ5180);
      }
    },
    showAz5181(value: boolean) {
      if (value && this.selectedAltbauAZ5181) {
        this.copyAltbauData(AZ5181);
      }
    },
    insuranceFormsApiData() {
      if (this.insuranceData === null) {
        this.fillApiValuesData();
      }
    },
    'Altbau.RisikoItems.Mitversicherung.risiko1': {
      handler(newVal) {
        this.AltbauNachAZ5155.value = newVal;
      },
      deep: true,
    },
    'Altbau.RisikoItems.Mitversicherung.risiko2': {
      handler(newVal) {
        this.AltbauNachAZ5180.value = newVal;
        if (this.disableAltbauRisiko3) {
          this.Altbau.RisikoItems.Mitversicherung.risiko3 = null;
          this.Altbau.RisikoItems.Entschadigungsvariante.risiko3 = null;
          this.Altbau.RisikoItems.Versicherungssumme.risiko3 = null;
          this.Altbau.RisikoItems.SelbstbeteiligungInPercent.risiko3 = null;
          this.Altbau.RisikoItems.SelbstbeteiligungInEUR.risiko3 = null;
          this.Altbau.RisikoItems.BeitragAltbau.risiko3 = null;
        }
      },
      deep: true,
    },
    'Altbau.RisikoItems.Mitversicherung.risiko3': {
      handler(newVal) {
        this.AltbauNachAZ5181.value = newVal;
        if (this.disableAltbauRisiko2) {
          this.Altbau.RisikoItems.Mitversicherung.risiko2 = null;
          this.Altbau.RisikoItems.Entschadigungsvariante.risiko2 = null;
          this.Altbau.RisikoItems.Versicherungssumme.risiko2 = null;
          this.Altbau.RisikoItems.SelbstbeteiligungInPercent.risiko2 = null;
          this.Altbau.RisikoItems.SelbstbeteiligungInEUR.risiko2 = null;
          this.Altbau.RisikoItems.BeitragAltbau.risiko2 = null;
        }
      },
      deep: true,
    },
    'AltbauNachAZ5155.value': {
      handler(newVal) {
        this.Altbau.RisikoItems.Mitversicherung.risiko1 = newVal;
      },
      deep: true,
    },
    'AltbauNachAZ5180.value': {
      handler(newVal) {
        this.Altbau.RisikoItems.Mitversicherung.risiko2 = newVal;
      },
      deep: true,
    },
    'AltbauNachAZ5181.value': {
      handler(newVal) {
        this.Altbau.RisikoItems.Mitversicherung.risiko3 = newVal;
      },
      deep: true,
    },
    'priceData.tarif.priceResponse': function (newVal, oldVal) {
      this.handlePriceResponse(newVal);
    },
    'priceData.variant1.priceResponse': function (newVal, oldVal) {
      this.handlePriceResponse(newVal);
    },
    'priceData.variant2.priceResponse': function (newVal, oldVal) {
      this.handlePriceResponse(newVal);
    },
    'priceData.variant3.priceResponse': function (newVal, oldVal) {
      this.handlePriceResponse(newVal);
    },
    altbauNachAZ5180IsDisable() {
      disableAltbauNachIfValuesFalse(this.AltbauNachAZ5180.items, this.AltbauNachAZ5180?.BesondereBaumabnahmenSelect);
    },
    altbauNachAZ5181IsDisable() {
      disableAltbauNachIfValuesFalse(this.AltbauNachAZ5181.items, this.AltbauNachAZ5181?.BesondereBaumabnahmenSelect);
    },
  },
  created() {
    this.createWatchers('AltbauNachAZ5155');
    this.customValidator.initErrorMessages(this.errorMessages as CustomErrorMessagesInterface);
    this.initValidators();
  },

  beforeDestroy() {
    if (!this.submitedForm) {
      this.onSubmit(false, false);
    }
  },

  methods: {
    initValidators() {
      this.customValidator.setValidators([
        {
          htmlId: 'contributionRate1',
          validate: () => this.disableSumInsuredForNewConstruction1 || +this.VersicherungssummeNeubauleistung.NeubauIndividualRisikogruppe1.BeitragssatzPercent > 0,
          message: 'Manuellen Beitragssatz eingeben, bitte mit der HV abstimmen!',
        },
        {
          htmlId: 'contributionRate2',
          validate: () => this.disableSumInsuredForNewConstruction2 || +this.VersicherungssummeNeubauleistung.NeubauIndividualRisikogruppe2.BeitragssatzPercent > 0,
          message: 'Manuellen Beitragssatz eingeben, bitte mit der HV abstimmen!',
        },
      ]);
    },
    handlePriceResponse(priceResponse: any) {
      if (!priceResponse.Result) return;

      const result = priceResponse.Result;

      // Extract contributions safely
      const altbauSachschadenBeitrag = result.Altbau?.['Altbau_Sachschäden']?.flatMap(
        (item) => item['Altbau_Sachschäden_Objekte']
      ) || [];

      const altbauEinsturzBeitrag = result.Altbau?.Altbau_Einsturz?.Altbau_Einsturz_Objekt || [];

      const specialConstructionMeasuresOldBuilding = result.Altbau?.['Altbau_Sachschäden'][0]?.['BesondereBaumaßnahmen_Altbau']?.['BesondereBaumaßnahmen_Altbau_Beitrag'] || 0;

      // Reset all premium categories if necessary
      this.resetPremiums();

      // Handle each type of premium category
      handlePremiumCategory(
        this.showAz5155,
        this.AltbauNachAZ5155.items,
        altbauEinsturzBeitrag,
        this.altbauPremiums.AltbauNachAZ5155,
        'Altbau_Einsturz_Beitragssatz',
        'VSU_Altbau_Einsturz',
        'Altbau_Einsturz_Beitrag',
        'altbauEinsturzBeitrag',
        {
        },
        0,
      );

      handlePremiumCategory(
        this.showAz5180,
        this.AltbauNachAZ5180.items,
        altbauSachschadenBeitrag,
        this.altbauPremiums.AltbauNachAZ5180,
        'Altbau_Sachschäden_Beitragssatz',
        'Altbau_Sachschaden_VSU',
        'Altbau_Sachschäden_Beitrag',
        'altbauSachschadenBeitrag',
        this.altbauNachAZ5180Coefficients,
        Number(specialConstructionMeasuresOldBuilding),
      );

      handlePremiumCategory(
        this.showAz5181,
        this.AltbauNachAZ5181.items,
        altbauSachschadenBeitrag,
        this.altbauPremiums.AltbauNachAZ5181,
        'Altbau_Sachschäden_Beitragssatz',
        'Altbau_Sachschaden_VSU',
        'Altbau_Sachschäden_Beitrag',
        'altbauSachschadenBeitrag',
        this.altbauNachAZ5181Coefficients,
        Number(specialConstructionMeasuresOldBuilding),
      );
    },

    resetPremiums() {
      this.altbauPremiums = {
        AltbauNachAZ5155: {
          AltbauIndividualA: {
          },
          AltbauIndividualB: {
          },
          AltbauIndividualC: {
          },
        },
        AltbauNachAZ5180: {
          AltbauIndividualA: {
          },
          AltbauIndividualB: {
          },
          AltbauIndividualC: {
          },
        },
        AltbauNachAZ5181: {
          AltbauIndividualA: {
          },
          AltbauIndividualB: {
          },
          AltbauIndividualC: {
          },
        },
      };
    },
    openCustomModal(modalName: string) {
      this.openedCustomModals[modalName] = true;
    },
    closeCustomModal(modalName: string) {
      this.openedCustomModals[modalName] = false;
    },
    openModal(title: string, body: string) {
      this.modalTitle = title;
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalTitle = '';
      this.modalBody = '';
      this.modalOpen = false;
    },
    onCurrencyFocus,
    clearGrundungsmabnahmenValues() {
      Object.keys(this.Grundungsmabnahmen).forEach((key) => {
        this.Grundungsmabnahmen[key].value = null;
      });
    },
    createWatchers(objectName) {
      const columns = ['AltbauIndividualA', 'AltbauIndividualB', 'AltbauIndividualC'];
      columns.forEach((column) => {
        this.$watch(
          `${objectName}.items.${column}.EinschlussBauherrenrisiko`,
          (newVal) => {
            this.syncValues(newVal, 'EinschlussBauherrenrisiko', objectName, column);
          },
          {
            deep: true,
          },
        );
        this.$watch(
          `${objectName}.items.${column}.EinschlussSturm`,
          (newVal) => {
            this.syncValues(newVal, 'EinschlussSturm', objectName, column);
          },
          {
            deep: true,
          },
        );
      });
    },
    syncValues(newVal, propertyName, objectName, columnName) {
      const columns = ['AltbauIndividualA', 'AltbauIndividualB', 'AltbauIndividualC'];
      columns.forEach((column) => {
        this[objectName].items[column][propertyName] = newVal;
      });
    },
    fillApiValuesData() {
      if (this.insuranceFormsApiData && this.insuranceFormsApiData.basicData) {
        // this.HilsbautenUndBauhilfsstoffe = this.insuranceFormsApiData.spreadsheetData.HilsbautenUndBauhilfsstoffe;
        recursiveFillData(this.Beschreibung, this.insuranceFormsApiData.spreadsheetData.Beschreibung);
        recursiveFillData(this.VersicherungssummeNeubauleistung, this.insuranceFormsApiData.spreadsheetData.VersicherungssummeNeubauleistung);
        recursiveFillData(this.SelbstbeteiligungNeubauleistung, this.insuranceFormsApiData.spreadsheetData.SelbstbeteiligungNeubauleistung);
        recursiveFillData(this.Grundungsmabnahmen, this.insuranceFormsApiData.spreadsheetData.Grundungsmabnahmen);
        recursiveFillData(this.MitversicherungBesondererBaumabnahmen, this.insuranceFormsApiData.spreadsheetData.MitversicherungBesondererBaumabnahmen);
        recursiveFillData(this.MitversicherungBesondererBaumabnahmen, this.insuranceFormsApiData.spreadsheetData.MitversicherungBesondererBaumabnahmen);
        recursiveFillData(this.HilsbautenUndBauhilfsstoffe, this.insuranceFormsApiData.spreadsheetData.HilsbautenUndBauhilfsstoffe);

        if (this.insuranceFormsApiData.spreadsheetData.Erstrisikosummen.additionalItems[0].name !== '') {
          this.addErstrisikosummenAdditionalItem();
        }
        recursiveFillData(this.Erstrisikosummen, this.insuranceFormsApiData.spreadsheetData.Erstrisikosummen);
        // recursiveFillData(this.Erstrisikosummen, this.insuranceFormsApiData.spreadsheetData.Erstrisikosummen);
        recursiveFillData(this.SachenImGefahrenbereich, this.insuranceFormsApiData.spreadsheetData.SachenImGefahrenbereich);
        recursiveFillData(this.MitversicherungVonFeuer, this.insuranceFormsApiData.spreadsheetData.MitversicherungVonFeuer);
        recursiveFillData(this.Deckungseinschrankungen, this.insuranceFormsApiData.spreadsheetData.Deckungseinschrankungen);
        recursiveFillData(this.Deckungserweiterungen, this.insuranceFormsApiData.spreadsheetData.Deckungserweiterungen);
        recursiveFillData(this.MitversicherungGewasserrisiko, this.insuranceFormsApiData.spreadsheetData.MitversicherungGewasserrisiko);
        recursiveFillData(this.Nachhaftung, this.insuranceFormsApiData.spreadsheetData.Nachhaftung);
        recursiveFillData(this.HochstentschadigungNaturgefahrenGemabAZ5793, this.insuranceFormsApiData.spreadsheetData.HochstentschadigungNaturgefahrenGemabAZ5793);
        recursiveFillData(this.Altbau, this.insuranceFormsApiData.spreadsheetData.Altbau);
        this.Altbau.RisikoItems.Klausel.risiko1 = this.insuranceFormsApiData.spreadsheetData.Altbau.RisikoItems.Klausel.risiko1;
        this.Altbau.RisikoItems.Klausel.risiko2 = this.insuranceFormsApiData.spreadsheetData.Altbau.RisikoItems.Klausel.risiko2;
        this.Altbau.RisikoItems.Klausel.risiko3 = this.insuranceFormsApiData.spreadsheetData.Altbau.RisikoItems.Klausel.risiko3;

        this.Altbau.RisikoItems.Entschadigungsvariante.risiko1 = this.insuranceFormsApiData.spreadsheetData.Altbau.RisikoItems.Entschadigungsvariante.risiko1;
        this.Altbau.RisikoItems.Entschadigungsvariante.risiko2 = this.insuranceFormsApiData.spreadsheetData.Altbau.RisikoItems.Entschadigungsvariante.risiko2;
        this.Altbau.RisikoItems.Entschadigungsvariante.risiko3 = this.insuranceFormsApiData.spreadsheetData.Altbau.RisikoItems.Entschadigungsvariante.risiko3;

        this.Altbau.RisikoItems.Mitversicherung.risiko1 = this.insuranceFormsApiData.spreadsheetData.Altbau.RisikoItems.Mitversicherung.risiko1 !== '' ? this.insuranceFormsApiData.spreadsheetData.Altbau.RisikoItems.Mitversicherung.risiko1 : null;
        this.Altbau.RisikoItems.Mitversicherung.risiko2 = this.insuranceFormsApiData.spreadsheetData.Altbau.RisikoItems.Mitversicherung.risiko2 !== '' ? this.insuranceFormsApiData.spreadsheetData.Altbau.RisikoItems.Mitversicherung.risiko2 : null;
        this.Altbau.RisikoItems.Mitversicherung.risiko3 = this.insuranceFormsApiData.spreadsheetData.Altbau.RisikoItems.Mitversicherung.risiko3 !== '' ? this.insuranceFormsApiData.spreadsheetData.Altbau.RisikoItems.Mitversicherung.risiko3 : null;


        recursiveFillData(this.AltbauNachAZ5155, this.insuranceFormsApiData.spreadsheetData.AltbauNachAZ5155);
        recursiveFillData(this.AltbauNachAZ5180, this.insuranceFormsApiData.spreadsheetData.AltbauNachAZ5180);
        recursiveFillData(this.AltbauNachAZ5181, this.insuranceFormsApiData.spreadsheetData.AltbauNachAZ5181);
      }
    },
    deleteErstrisikosummenItem(key) {
      this.Erstrisikosummen.additionalItems.splice(key, 1);
    },
    addErstrisikosummenAdditionalItem() {
      this.Erstrisikosummen.additionalItems.push({
        ...copyObject(GrundwertAdditionalItem),
      });
    },
    goToBackUrl() {
      this.$emit('go-back-tab', this.backUrl, 'insuranceForms', 1);
    },
    queueGetPriceRequest(variantPrice: string) {
      this.$emit('add-to-queue', 'getPriceRequestUseId', variantPrice);
      this.onSubmit(false, false);
    },
    queueGetPrice(variantPrice: string) {
      this.$emit('add-to-queue', 'getPriceUseId', variantPrice);
      this.onSubmit(false, false);
    },
    setValues(insuranceData: any) {
      if (insuranceData.spreadsheetData !== null && this.key === this.insuranceKey) {
        const calculationData = insuranceData.spreadsheetData;
        this.Beschreibung = calculationData.Beschreibung;
        this.VersicherungssummeNeubauleistung = calculationData.VersicherungssummeNeubauleistung || copyObject(versicherungssummeNeubauleistungObject);
        this.SelbstbeteiligungNeubauleistung = calculationData.SelbstbeteiligungNeubauleistung || copyObject(selbstbeteiligungNeubauleistungObject);
        this.Altbau = calculationData.Altbau || copyObject(altbauObject);
        this.Grundungsmabnahmen = calculationData.Grundungsmabnahmen;
        this.MitversicherungBesondererBaumabnahmen = calculationData.MitversicherungBesondererBaumabnahmen;
        this.HilsbautenUndBauhilfsstoffe = calculationData.HilsbautenUndBauhilfsstoffe || copyObject(hilsbautenUndBauhilfsstoffeObject);
        this.Erstrisikosummen = calculationData.Erstrisikosummen;
        this.SachenImGefahrenbereich = calculationData.SachenImGefahrenbereich;
        this.MitversicherungVonFeuer = calculationData.MitversicherungVonFeuer;
        this.Deckungseinschrankungen = calculationData.Deckungseinschrankungen;
        this.Deckungserweiterungen = calculationData.Deckungserweiterungen;
        this.MitversicherungGewasserrisiko = calculationData.MitversicherungGewasserrisiko;
        this.Nachhaftung = calculationData.Nachhaftung;
        this.HochstentschadigungNaturgefahrenGemabAZ5793 = calculationData.HochstentschadigungNaturgefahrenGemabAZ5793 || copyObject(hochstentschadigungNaturgefahrenGemabAZ5793Object);
        this.AltbauNachAZ5155 = calculationData.AltbauNachAZ5155 || copyObject(altbauNachAZ5155Object);
        this.AltbauNachAZ5180 = calculationData.AltbauNachAZ5180 || copyObject(altbauNachAZ5180Object);
        this.AltbauNachAZ5181 = calculationData.AltbauNachAZ5181 || copyObject(altbauNachAZ5181Object);
        this.altbauPremiums = calculationData.altbauPremiums ?? this.altbauPremiums;
      }
    },
    onSubmit(nextPriceTab = false, changeTabId = true) {
      this.VersicherungssummeNeubauleistung.NeubauIndividualRisikogruppe1.EinzelbeitragNeubauleistung = this.einzelbeitragNeubauleistungGroup1;
      this.VersicherungssummeNeubauleistung.NeubauIndividualRisikogruppe2.EinzelbeitragNeubauleistung = this.einzelbeitragNeubauleistungGroup2;

      const data = {
        Beschreibung: this.Beschreibung,
        VersicherungssummeNeubauleistung: this.VersicherungssummeNeubauleistung,
        SelbstbeteiligungNeubauleistung: this.SelbstbeteiligungNeubauleistung,
        Altbau: this.Altbau,
        Grundungsmabnahmen: this.Grundungsmabnahmen,
        MitversicherungBesondererBaumabnahmen: this.MitversicherungBesondererBaumabnahmen,
        HilsbautenUndBauhilfsstoffe: this.HilsbautenUndBauhilfsstoffe,
        Erstrisikosummen: this.Erstrisikosummen,
        SachenImGefahrenbereich: this.SachenImGefahrenbereich,
        MitversicherungVonFeuer: this.MitversicherungVonFeuer,
        Deckungseinschrankungen: this.Deckungseinschrankungen,
        Deckungserweiterungen: this.Deckungserweiterungen,
        MitversicherungGewasserrisiko: this.MitversicherungGewasserrisiko,
        Nachhaftung: this.Nachhaftung,
        HochstentschadigungNaturgefahrenGemabAZ5793: this.HochstentschadigungNaturgefahrenGemabAZ5793,
        AltbauNachAZ5155: this.AltbauNachAZ5155,
        AltbauNachAZ5180: this.AltbauNachAZ5180,
        AltbauNachAZ5181: this.AltbauNachAZ5181,
        showAz5155: this.showAz5155,
        showAz5180: this.showAz5180,
        showAz5181: this.showAz5181,
        altbauPremiums: this.altbauPremiums,
      };
      this.$emit('spreadsheet-data-change', data, this.key, nextPriceTab, changeTabId);

      this.submitedForm = true;
    },
    downloadPDF() {
      exportToPDF.call(this, 'Zusammenfassung-Bauleistung-Tarif.pdf');
    },
    savePdf() {
      this.customValidator.validate();

      if (this.customValidator.getTotalValidationResult()) {
        saveZusammenfassungPDF.call(this, this.id, 'saveTarifPDF', this.onSubmit);
      }
      // this.onSubmit(false);
    },
    getKlauselRisikoOfSelectedAltbau(altbauType: string) {
      if (this.Altbau.RisikoItems.Klausel.risiko1 === altbauType) {
        return 'risiko1';
      }

      if (this.Altbau.RisikoItems.Klausel.risiko2 === altbauType) {
        return 'risiko2';
      }

      if (this.Altbau.RisikoItems.Klausel.risiko3 === altbauType) {
        return 'risiko3';
      }

      return null;
    },
    copyAltbauData(altbauType: string): void {
      const risk = this.getKlauselRisikoOfSelectedAltbau(altbauType);

      let selectedAltbau = null as any;

      switch (altbauType) {
        case AZ5155: selectedAltbau = this.AltbauNachAZ5155.items.AltbauIndividualA; break;
        case AZ5180: selectedAltbau = this.AltbauNachAZ5180.items.AltbauIndividualA; break;
        case AZ5181: selectedAltbau = this.AltbauNachAZ5181.items.AltbauIndividualA; break;
        default: break;
      }

      if (risk) {
        const dataToCopy = {
          ...(this.Altbau.RisikoItems.Versicherungssumme[risk] !== null &&
            selectedAltbau?.VSinEUR === null && {
            VSinEUR: this.Altbau.RisikoItems.Versicherungssumme[risk],
          }),
          ...(this.Altbau.RisikoItems.SelbstbeteiligungInPercent[risk] !== null &&
            selectedAltbau?.SBinPercent === null && {
            SBinPercent: this.Altbau.RisikoItems.SelbstbeteiligungInPercent[risk],
          }),
          ...(this.Altbau.RisikoItems.SelbstbeteiligungInEUR[risk] !== null &&
            selectedAltbau?.SBinEUR === null && {
            SBinEUR: this.Altbau.RisikoItems.SelbstbeteiligungInEUR[risk],
          }),
        };

        switch (altbauType) {
          case AZ5155: this.copyAltbauDataTo5155(dataToCopy); break;
          case AZ5180: this.copyAltbauDataTo5180(dataToCopy); break;
          case AZ5181: this.copyAltbauDataTo5181(dataToCopy); break;
          default: break;
        }
      }
    },
    copyAltbauDataTo5155(data: object): void {
      this.AltbauNachAZ5155.items.AltbauIndividualA = {
        ...this.AltbauNachAZ5155.items.AltbauIndividualA,
        ...data,
      };
    },
    copyAltbauDataTo5180(data: object): void {
      this.AltbauNachAZ5180.items.AltbauIndividualA = {
        ...this.AltbauNachAZ5180.items.AltbauIndividualA,
        ...data,
      };
    },
    copyAltbauDataTo5181(data: object): void {
      this.AltbauNachAZ5181.items.AltbauIndividualA = {
        ...this.AltbauNachAZ5181.items.AltbauIndividualA,
        ...data,
      };
    },
    roundInput(value, property) {
      // Parse the input value to a floating point number
      const parsedValue = parseFloat(value);
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(parsedValue)) {
        this.HilsbautenUndBauhilfsstoffe.procent = Number(parsedValue.toFixed(2));
      } else {
        this.HilsbautenUndBauhilfsstoffe.procent = null;
      }
    },
  },
  computed: {
    altbauNachAZ5180Coefficients(): object {
      return distributeCoefficients(this.AltbauNachAZ5180?.BesondereBaumabnahmenSelect);
    },
    altbauNachAZ5181Coefficients(): object {
      return distributeCoefficients(this.AltbauNachAZ5181?.BesondereBaumabnahmenSelect);
    },
    altbauNachAZ5180IsDisable(): object {
      return altbauNachIsDisable(this.AltbauNachAZ5180.items);
    },
    altbauNachAZ5181IsDisable(): object {
      return altbauNachIsDisable(this.AltbauNachAZ5181.items);
    },
    disableSumInsuredForNewConstruction1(): boolean {
      return (+this.VersicherungssummeNeubauleistung.NeubauIndividualRisikogruppe1.Versicherungssumme) <= 25000000;
    },
    disableSumInsuredForNewConstruction2(): boolean {
      return (+this.VersicherungssummeNeubauleistung.NeubauIndividualRisikogruppe2.Versicherungssumme) <= 25000000;
    },
    totalVersicherungssumme(): number {
      return (+this.VersicherungssummeNeubauleistung.NeubauIndividualRisikogruppe1.Versicherungssumme) +
        (+this.VersicherungssummeNeubauleistung.NeubauIndividualRisikogruppe2.Versicherungssumme);
    },
    disableAZ5180IndividualVariant(): boolean {
      return this.AltbauNachAZ5180.Entschadigungsvariante === ersZumNeuwert ||
        this.AltbauNachAZ5180.Entschadigungsvariante === ersZumZeitwert;
    },
    disableAZ5181CompensationVariant(): boolean {
      return this.AltbauNachAZ5181.Deckungsvariante === newConstruction;
    },
    disableAZ5181IndividualVariant(): boolean {
      return this.AltbauNachAZ5181.Deckungsvariante === erstesRisiko;
    },
    disbleEstablishmentMeasuresFields(): boolean {
      return this.totalVersicherungssumme < disableEstablishmentMeasuresInputsCondition;
    },
    specialConstructionMeasuresBody(): string {
      return `
          <p>(in ‰ aus der jeweiligen Erstrisikosumme dieser Sachen)</p>
          <table class="table">
            <tr>
              <td>Medizin- und labortechnische Anlagen<br>
                abhängig von Höhe der ERS und Vertragslaufzeit ㅤㅤㅤㅤㅤㅤㅤ<b class="float-right">Abstimmung UW</b>
              </td>
              <td>5,0-8,0‰</td>
            </tr>
            <tr>
              <td>Datenverarbeitungsanlagen u. Anlagen der Bild-u. Tontechnik<br>
                abhängig von Höhe der ERS und Vertragslaufzeit ㅤㅤㅤㅤㅤㅤㅤ<b class="float-right">Abstimmung UW</b>
              </td>
              <td>5,0-8,0‰</td>
            </tr>
            <tr>
              <td>Sonstige Energieerzeugungsanlagen, Notstromaggregate,
                <br>
                abhängig von Höhe der ERS und Vertragslaufzeit ㅤㅤㅤㅤㅤㅤㅤ<b class="float-right">Abstimmung UW</b>
              </td>
              <td>5,0-8,0‰</td>
            </tr>
            <tr>
              <td>Besondere nutzungsspezifische Anlagen
                <br>
                abhängig von Höhe der ERS und Vertragslaufzeit ㅤㅤㅤㅤㅤㅤㅤ<b class="float-right">Abstimmung UW</b>
              </td>
              <td>5,0-8,0‰</td>
            </tr>
            <tr>
              <td>Maschinelle Einrichtungen für Produktionszwecke
                <br>
                abhängig von Höhe der ERS und Vertragslaufzeit ㅤㅤㅤㅤㅤㅤㅤ<b class="float-right">Abstimmung UW</b>
              </td>
              <td>5,0-8,0‰</td>
            </tr>
            <tr>
              <td>Aufwendige Ausstattung, kunsthandwerklich bearbeitete Bauteile
                <br>
                abhängig von Höhe der ERS und Vertragslaufzeit ㅤㅤㅤㅤㅤㅤㅤ<b class="float-right">Abstimmung UW</b>
              </td>
              <td>25,0‰</td>
            </tr>
            <tr>
              <td>Bestandteile von unverhältnismäßig hohem Kunstwert abhängig von Höhe der ERS und Vertragslaufzeit
                <br>
                abhängig von Höhe der ERS und Vertragslaufzeit ㅤㅤㅤㅤㅤㅤㅤ<b class="float-right">Abstimmung UW</b>
              </td>
              <td>25-75‰</td>
            </tr>
            <tr>
              <td colspan="2">Die Erstrisikosumme für besonders zu versichemde Sachen darf die Höhe der Versiche-<br>
              rungssumme für den Altbau nicht übersteigen! Bei Überschreitung: Abstimmung HV
              </td>
            </tr>
            <tr>
              <td>Kosten zur Lokalisierung von Schadenursachen
              </td>
              <td>10,0‰</td>
            </tr>
          </table>`;
    },
    specialConstructionMeasuresBody5181(): string {
      return `
          <p>(in ‰ aus der jeweiligen Erstrisikosumme dieser Sachen)</p>
          <table class="table">
            <tr>
              <td>Medizin- und labortechnische Anlagen<br>
                abhängig von Höhe der ERS und Vertragslaufzeit ㅤㅤㅤㅤㅤㅤㅤ<b class="float-right">Abstimmung UW</b>
              </td>
              <td>6,0-10,0‰</td>
            </tr>
            <tr>
              <td>Datenverarbeitungsanlagen u. Anlagen der Bild-u. Tontechnik<br>
                abhängig von Höhe der ERS und Vertragslaufzeit ㅤㅤㅤㅤㅤㅤㅤ<b class="float-right">Abstimmung UW</b>
              </td>
              <td>6,0-10,0‰</td>
            </tr>
            <tr>
              <td>Sonstige Energieerzeugungsanlagen, Notstromaggregate,
                <br>
                abhängig von Höhe der ERS und Vertragslaufzeit ㅤㅤㅤㅤㅤㅤㅤ<b class="float-right">Abstimmung UW</b>
              </td>
              <td>6,0-10,0‰</td>
            </tr>
            <tr>
              <td>Besondere nutzungsspezifische Anlagen
                <br>
                abhängig von Höhe der ERS und Vertragslaufzeit ㅤㅤㅤㅤㅤㅤㅤ<b class="float-right">Abstimmung UW</b>
              </td>
              <td>6,0-10,0‰</td>
            </tr>
            <tr>
              <td>Maschinelle Einrichtungen für Produktionszwecke
                <br>
                abhängig von Höhe der ERS und Vertragslaufzeit ㅤㅤㅤㅤㅤㅤㅤ<b class="float-right">Abstimmung UW</b>
              </td>
              <td>6,0-10,0‰</td>
            </tr>
            <tr>
              <td>Aufwendige Ausstattung, kunsthandwerklich bearbeitete Bauteile
                <br>
                 ㅤㅤㅤㅤㅤㅤㅤ<b class="float-right">Abstimmung UW</b>
              </td>
              <td>30,0‰</td>
            </tr>
            <tr>
              <td>Bestandteile von unverhältnismäßig hohem Kunstwert abhängig von Höhe der ERS und Vertragslaufzeit
                <br>
                 ㅤㅤㅤㅤㅤㅤㅤ<b class="float-right">Abstimmung UW</b>
              </td>
              <td>30-100‰</td>
            </tr>
            <tr>
              <td colspan="2">Die Erstrisikosumme für besonders zu versichemde Sachen darf die Höhe der Versiche-<br>
              rungssumme für den Altbau nicht übersteigen! Bei Überschreitung => Abstimmung HV
              </td>
            </tr>
            <tr>
              <td>Kosten zur Lokalisierung von Schadenursachen
              </td>
              <td>10,0‰</td>
            </tr>
          </table>`;
    },
    versicherbareKostenpositionenLimitItemsToolTip(): string {
      return `
          <p>Bei den nach § 6 Nr. 3 f-i) versicherbaren Kostenpositionen (Reparaturkosten an fremden</p>
<p>Sachen / Hotel- und Einlagerungskosten / Mietausfall / Bewegungskosten an nicht</p>
<p>versicherten Sachen), darf eine Erstrisikosumme von jeweils 25.000 EUR nicht</p>
<p>überschritten werden. Im Ausnahmefall ist eine Erhöhung auf maximal 50.000,- EUR möglich.</p>`;
    },
    // tarifPriceResponce() {
    //   return this.priceData.tarif.priceResponse;
    // },
    einzelbeitragNeubauleistungGroup1(): number {
      return Number((((this.VersicherungssummeNeubauleistung.NeubauIndividualRisikogruppe1.Versicherungssumme || 0) / 1000) *
        (this.VersicherungssummeNeubauleistung.NeubauIndividualRisikogruppe1.BeitragssatzPercent || 0)).toFixed(2));
    },
    einzelbeitragNeubauleistungGroup2(): number {
      return Number((((this.VersicherungssummeNeubauleistung.NeubauIndividualRisikogruppe2.Versicherungssumme || 0) / 1000) *
        (this.VersicherungssummeNeubauleistung.NeubauIndividualRisikogruppe2.BeitragssatzPercent || 0)).toFixed(2));
    },
    showAltbauHeaderSelectField(): boolean {
      return this.Altbau.RisikoItems.Klausel.risiko1 !== null ||
        this.Altbau.RisikoItems.Klausel.risiko2 !== null ||
        this.Altbau.RisikoItems.Klausel.risiko3 !== null;
    },
    selectedAltbauAZ5155(): boolean {
      return this.Altbau.RisikoItems.Klausel.risiko1 === AZ5155 ||
        this.Altbau.RisikoItems.Klausel.risiko2 === AZ5155 ||
        this.Altbau.RisikoItems.Klausel.risiko3 === AZ5155;
    },
    selectedAltbauAZ5180(): boolean {
      return this.Altbau.RisikoItems.Klausel.risiko1 === AZ5180 ||
        this.Altbau.RisikoItems.Klausel.risiko2 === AZ5180 ||
        this.Altbau.RisikoItems.Klausel.risiko3 === AZ5180;
    },
    selectedAltbauAZ5181(): boolean {
      return this.Altbau.RisikoItems.Klausel.risiko1 === AZ5181 ||
        this.Altbau.RisikoItems.Klausel.risiko2 === AZ5181 ||
        this.Altbau.RisikoItems.Klausel.risiko3 === AZ5181;
    },
    altbauIndividuelleSelected(): boolean {
      return this.Altbau.IndividuelleRisikoinformationenZumAltbau.value === 'Ja';
    },
    showAz5155(): boolean {
      return this.showAz5155Select && this.altbauIndividuelleSelected && (this.AltbauNachAZ5155.value === 'Ja' || this.AltbauNachAZ5155.value === 'Optional');
    },
    showAz5180(): boolean {
      return this.showAz5180Select && this.altbauIndividuelleSelected && (this.AltbauNachAZ5180.value === 'Ja' || this.AltbauNachAZ5180.value === 'Optional');
    },
    showAz5181(): boolean {
      return this.showAz5181Select && this.altbauIndividuelleSelected && (this.AltbauNachAZ5181.value === 'Ja' || this.AltbauNachAZ5181.value === 'Optional');
    },
    altbauSelected(): boolean {
      return this.Altbau.selected === 'Ja' || this.Altbau.selected === 'Optional';
    },
    showAz5155Select(): boolean {
      return this.altbauIndividuelleSelected && this.altbauSelected && this.selectedAltbauAZ5155;
    },
    showAz5180Select(): boolean {
      return this.altbauIndividuelleSelected && this.altbauSelected && this.selectedAltbauAZ5180;
    },
    showAz5181Select(): boolean {
      return this.altbauIndividuelleSelected && this.altbauSelected && this.selectedAltbauAZ5181;
    },
    showPlusBtn(): boolean {
      return (this.Erstrisikosummen.additionalItems.length < 5);
    },
    showMinusBtn(): boolean {
      return (this.Erstrisikosummen.additionalItems.length > 1);
    },
    disableSpecialConstructionMeasures(): boolean {
      return this.AltbauNachAZ5180.Entschadigungsvariante === ersZumNeuwert ||
        this.AltbauNachAZ5180.Entschadigungsvariante === ersZumZeitwert;
    },
    disableSpecialConstructionMeasuresAZ5181(): boolean {
      return this.AltbauNachAZ5181.Deckungsvariante === erstesRisiko;
    },
    disableAltbauRisiko2(): boolean {
      return (this.Altbau.RisikoItems.Mitversicherung.risiko3 === 'Ja' || this.Altbau.RisikoItems.Mitversicherung.risiko3 === 'Optional');
    },
    disableAltbauRisiko3(): boolean {
      return (this.Altbau.RisikoItems.Mitversicherung.risiko2 === 'Ja' || this.Altbau.RisikoItems.Mitversicherung.risiko2 === 'Optional');
    },
    isApiUser(): boolean {
      return this.$store.getters['authModule/isApiUser'];
    },
    isPCUser(): boolean {
      return this.$store.getters['authModule/isPCUser'];
    },
  },
  mounted() {
    (this as any).resetWatcherActivated();
    if (this.insuranceData && this.insuranceData.spreadsheetData) {
      this.setValues(this.insuranceData);
    } else {
      this.fillApiValuesData();
    }
    this.$emit('on-key-change', this.key);
    (this as any).startWatcherActivated();
  },
});
